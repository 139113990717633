<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="80%" max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#8950FC" dark small v-bind="attrs" v-on="on">
          <span v-if="academic_year_id != null && academic_year_name != ''">
            {{ academic_year_name }}
          </span>
          <span v-else>Akademik Yıl</span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Akademik yılı değiştiriyorsunuz
        </v-card-title>
        <v-container>
          <c-academic-year
            v-model="academic_year_id"
            hide-details
            label="Akademik yıl seçimi"
            clearable="false"
          ></c-academic-year>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Vazgeç </v-btn>
          <v-btn :loading="loadingSave" color="primary" text @click="change"> Değiştir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import { CHANGE_TOKEN } from '@/core/services/store/auth.module'
import { mapGetters } from 'vuex'

export default {
  name: 'academic-year-changer',
  data() {
    return {
      dialog: false,
      loading: false, //aslında true olması lazım
      loadingSave: false,
      academic_year_id: null,
      academic_year_name: ''
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.$nextTick(() => {
      this.getAcademicYearFromApi()
    })
  },
  methods: {
    change() {
      this.loadingSave = true
      ApiService.setHeader()
      ApiService.post('api/changeacademicyear/' + this.academic_year_id)
        .then(({ data }) => {
          this.$store.dispatch(CHANGE_TOKEN, data)
          window.location.reload(true)
          this.loadingSave = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loadingSave = false
        })
    },
    getAcademicYearFromApi(x = 0) {
      this.loading = true
      if (
        this.currentUser &&
        this.currentUser.User &&
        this.currentUser.User.User &&
        this.currentUser.User.User.SelectedAcademicYearId
      ) {
        this.academic_year_id = this.currentUser.User.User.SelectedAcademicYearId
        ApiService.setHeader()
        ApiService.get('api/AcademicYear/' + this.academic_year_id)
          .then(({ data }) => {
            this.academic_year_name = data.Name
            this.loading = false
          })
          .catch(({ response }) => {
            ApiService.showError(response)
          })
      } else if (x < 10) {
        setTimeout(() => {
          this.getAcademicYearFromApi(x + 1)
        }, 500)
      }
    }
  }
}
</script>
<style>
.v-dialog {
  position: absolute;
  top: 6%;
}
</style>
